import { useState, useEffect } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_
import { Link } from 'react-router-dom';

//Fireebase
import { getAuth } from 'firebase/auth';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const [Email, setEmail] = useState('');
  const [UserName, setUserName] = useState('');
  const [Profile, setProfile] = useState('');

  const auth = getAuth();
  useEffect(() => {
    if (auth.currentUser !== null) {
      auth.currentUser.providerData.map((profile) => {
        setEmail(profile.email);
        setUserName(profile.displayName);
        setProfile(profile.photoURL);
      });
    }
  }, []);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={Profile || '/assets/images/avatars/avatar_default.jpg'} alt="photoURL" />{' '}
      </IconButton>
      <Typography onClick={handleOpen} role="button" className="text-muted" variant="subtitle2" noWrap>
        {UserName}
      </Typography>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {UserName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {Email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/dashboard/settings">
          <MenuItem sx={{ m: 1 }}>Settings</MenuItem>
        </Link>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/logout">
          <MenuItem onClick={handleClose} sx={{ m: 1 }}>
            Logout
          </MenuItem>
        </Link>
      </Popover>
    </>
  );
}
