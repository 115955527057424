import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Iconify from 'src/components/iconify/Iconify';
import Popover from '@mui/material/Popover';
import {
  Divider,
  TextField,
  Grid,
  Paper,
  Typography,
  ListItem,
  Fab,
  ListItemText,
  List,
  Avatar,
  Input,
  Button,
  Stack,
  Checkbox,
  Alert,
  Container,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import {
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
  collection,
  updateDoc,
  addDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db } from '../firebase';
import { toast } from 'react-toastify';

const BoardPage = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const auth = getAuth();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const { board } = useParams();
  const [boardData, setBoardData] = useState();
  const [userData, setUserData] = useState({});
  const [warning, setWarnning] = useState(false);

  const handleClick = (event) => {
    console.log(userData);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {



    const fetchData = async () => {
      try {
        // Create a query to find boards where participants array contains the user's UID
        //const boardQuery = query(collection(db, board), where('participants', 'array-contains', auth.currentUser.uid));
        const boardQuery = query(
          collection(db, board),
          where('participants', 'array-contains', auth.currentUser.uid),
          orderBy('createdOn', 'desc'), // Order by the createdOn field in descending order
          limit(1) // Get only the latest document
        );
        // Attach a listener to the query using onSnapshot
        const unsubscribe = onSnapshot(boardQuery, (snapshot) => {
          if (!snapshot.empty) {
            // Assuming you want to work with the first matching board
            const firstMatchingBoard = snapshot.docs[0];

            // Get the document ID and data
            const boardId = firstMatchingBoard.id;
            const boardData = firstMatchingBoard.data();


            // Save the document ID as "id" in the boardData
            boardData.id = boardId;

            // Set the boardData state
            setBoardData(boardData);
            boardData?.leftGifters?.map((gifter, index) => {
              if (gifter.uid == auth.currentUser.uid) {
                if (gifter.status == 'pending') {
                  setWarnning(true);
                }
              }
            });
            boardData?.rightGifters?.map((gifter, index) => {
              if (gifter.uid == auth.currentUser.uid) {
                if (gifter.status == 'pending') {
                  setWarnning(true);
                }
              }
            });
          } else {
            // toast.error('Error Board Not Found. Contact Support');
            navigate('/');
            console.log('No matching boards found.');
          }
        });

        // Return the unsubscribe function in case you want to stop listening later
        return unsubscribe;
      } catch (error) {
        toast.error('Error Board Not Found. Contact Support');
        console.error('Error fetching boards:', error);
      }
    };

    // To start listening for updates, call fetchData and keep the returned unsubscribe function.
    const unsubscribe = fetchData();

    // Call the fetchData function when the component mounts
    fetchData();
  }, []);

  const promoteConfimedGifter = (user) => {
    if (!boardData?.leftGuide?.uid || !boardData?.rightGuide?.uid) {
      console.log(boardData.id);
      console.log(board);
      getDoc(doc(db, board, boardData.id))
        .then((data) => {
          console.log('Data', data.data());
          var newBoardData = data.data();
          if (!newBoardData.leftGuide?.uid || !newBoardData.rightGuide?.uid) {
            if (!newBoardData.leftGuide?.uid) {
              if (user.side == 'left') {
                newBoardData.leftGuide = newBoardData.leftGifters[user.index];
                newBoardData = removeGifter(newBoardData, newBoardData.leftGifters[user.index]);
              } else {
                newBoardData.leftGuide = newBoardData.rightGifters[user.index];
                newBoardData = removeGifter(newBoardData, newBoardData.rightGifters[user.index]);
              }
            } else if (!newBoardData.rightGuide?.uid) {
              if (user.side == 'right') {
                newBoardData.rightGuide = newBoardData.rightGifters[user.index];
                newBoardData = removeGifter(newBoardData, newBoardData.rightGifters[user.index]);
              } else {
                newBoardData.rightGuide = newBoardData.leftGifters[user.index];
                newBoardData = removeGifter(newBoardData, newBoardData.leftGifters[user.index]);
              }
            }
            updateDoc(doc(db, board, boardData.id), newBoardData).catch((error) => {
              console.log('Error on Update', error);
            });
          }
        })
        .catch((error) => {
          console.log('Error on Fetch', error);
        });
    }
  };

  const changeGifterStatus = async (user, status) => {
    // Display a confirmation dialog
    const confirmed = window.confirm(`Are you sure you want to set ${user.username}'s status to ${status}?`);

    if (!confirmed) {
      // If the user cancels the confirmation, exit the function
      return;
    }

    getDoc(doc(db, board, boardData.id))
      .then((data) => {
        const newBoard = data.data();
        if (newBoard.lagend.uid != auth.currentUser.uid) {
          toast.error('You do not have permissions to perform this action.');
          return;
        }

        if (user.side == 'left') {
          newBoard.leftGifters[user.index].status = status;

          updateDoc(doc(db, board, boardData.id), { leftGifters: newBoard.leftGifters }).then(() => {
            promoteConfimedGifter(user);
            toast.success('Gifter ' + user.username + ' is ' + status);
            handleClose();
          });
        } else {
          newBoard.rightGifters[user.index].status = status;
          updateDoc(doc(db, board, boardData.id), { rightGifters: newBoard.rightGifters }).then(() => {
            promoteConfimedGifter(user);
            toast.success('Gifter ' + user.username + ' is ' + status);
            handleClose();
          });
        }
      })
      .then(() => {
        if (user.giftStatus != 'confirmed') {
          updateDoc(doc(db, 'users', user.uid), { giftStatus: 'confirmed' }).catch((error) => {
            console.log('Error while chaging user gift status', error);
          });
        }
      })
      .catch((error) => {
        toast.error('Error Try again.');
        console.log(error);
      });
  };

  const removeGifter = (targetBoard, user) => {
    targetBoard.leftGifters = targetBoard?.leftGifters?.filter((gifter) => gifter?.uid !== user?.uid);
    targetBoard.rightGifters = targetBoard?.rightGifters?.filter((gifter) => gifter?.uid !== user?.uid);
    console.log('gifter removed');
    return targetBoard;
  };
  const promoteGifters = (targetBoard, leftGuide, rightGuide, lagend, user) => {
    const gifters = [];

    targetBoard.leftGifters?.map((gifter) => {
      if (gifter.status == 'approved') {
        gifters.push(gifter);
      }
    });
    targetBoard.rightGifters?.map((gifter) => {
      if (gifter.status == 'approved') {
        gifters.push(gifter);
      }
    });
    if (gifters.length > 1) {
      // Sort gifters by boardJoiningDate in descending order to the oldest gifter shouold be promoted to guide

      gifters.sort((a, b) => {
        const dateA = a.boardJoiningDate?.toDate();
        const dateB = b.boardJoiningDate?.toDate();

        // Compare dates in descending order
        return dateB - dateA;
      });
    }
    console.log(gifters);
    if (lagend) {
      //Checking if lagend left the board
      if (targetBoard.leftGuide?.uid && targetBoard.rightGuide?.uid) {
        //if both guides are available on board then promote the one to a lagend who has joint the board earlier

        const isLeftGuideOld = targetBoard.leftGuide.boardJoiningDate >= targetBoard.rightGuide.boardJoiningDate;
        const promotedGuide = isLeftGuideOld ? targetBoard.leftGuide : targetBoard.rightGuide;

        targetBoard.lagend = promotedGuide;

        if (gifters.length > 0) {
          if (isLeftGuideOld) {
            targetBoard.leftGuide = gifters[0];
          } else {
            targetBoard.rightGuide = gifters[0];
          }
          targetBoard = removeGifter(targetBoard, gifters[0]);
        } else {
          if (isLeftGuideOld) {
            targetBoard.leftGuide = {};
          } else {
            targetBoard.rightGuide = {};
          }
        }
      } else if (targetBoard.leftGuide?.uid) {
        if (gifters[0]) {
          //if there is any gifter in board promoting it to fill the place of guide who is promoted to lagend.
          targetBoard.leftGuide = gifters[0];
          targetBoard = removeGifter(targetBoard, gifters[0]);
        } else {
          targetBoard.leftGuide = {};
        }
      } else if (targetBoard.rightGuide?.uid) {
        if (gifters[0]) {
          targetBoard.rightGuide = gifters[0];
          targetBoard = removeGifter(targetBoard, gifters[0]);
        } else {
          targetBoard.rightGuide = {};
        }
      } else if (targetBoard.participants.length >= 1 && gifters.length == 0) {
        //if there is no guides to promote to lagend and also there are no gifters on the board then return false to delete the whole board.

        return false;
      }
    } else if (leftGuide || rightGuide) {
      //if any guide left the board
      if (gifters.length > 0) {
        if (leftGuide) {
          targetBoard.leftGuide = gifters[0];
        } else if (rightGuide) {
          targetBoard.rightGuide = gifters[0];
        }
        //deleteing the gifter after promoting to guide.
        targetBoard = removeGifter(targetBoard, gifters[0]);
      } else {
        //if there are no gifter available on board
        if (leftGuide) {
          targetBoard.leftGuide = {};
        } else if (rightGuide) {
          targetBoard.rightGuide = {};
        }
      }
    } else {
      toast.error('Something went wrong.Please reload the page');
    }

    return targetBoard; //return the updated Board Data to save;
  };

  const quitGiftProsperity = (user) => {
    const confirmed = window.confirm(
      `Are you sure you want to quit gift prosperity? If you click on OK you will be removed from the board.`
    );

    if (!confirmed) {
      // If the user cancels the confirmation, exit the function
      return;
    }

    const index = boardData?.participants?.indexOf(user.uid);
    if (index < 0) {
      toast.error('You are not a participant of this board.');
      return;
    }
    const removed = boardData?.participants?.splice(index, 1);
    boardData.totalMembers = boardData.participants.length;

    var result = false;
    if (removed) {
      if (boardData?.leftGuide.uid === user.uid) {
        result = promoteGifters(boardData, true, false, false, user);
      } else if (boardData?.rightGuide.uid === user.uid) {
        result = promoteGifters(boardData, false, true, false, user);
      } else if (boardData.lagend.uid === user.uid) {
        result = promoteGifters(boardData, false, false, true, user);
      } else {
        result = removeGifter(boardData, user);
      }
    } else {
      toast.error('Error.Try again');
    }

    if (result) {
      updateDoc(doc(db, board, boardData.id), result)
        .then(() => {
          toast.success('You are no Longer Part of this board.');
        })
        .catch((error) => {
          toast.error('Error try again.');
          console.log(error);
        });
    } else {
      if (boardData.participants.length < 1) {
        deleteDoc(doc(db, board, boardData.id)).catch((error) => {
          console.log('Error while deleteing board', error);
        });
      }
    }
  };
  return (
    <>
      {boardData && (
        <div>
          <Helmet>
            <title> Board | Gift Prosperity</title>
          </Helmet>
          <div className=" d-flex justify-content-between">
            <div className="col-md-6">
              <Link className="ms-auto " to="/dashboard/app">
                <Button variant="outlined">Back</Button>
              </Link>
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <Link className="ms-auto " to={'/dashboard/chat/' + board}>
                <Button variant="outlined">Board Chat</Button>
              </Link>
            </div>
          </div>

          {userData?.username && (
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <List>
                <ListItem>
                  <b className="text-center">User Infobox</b>
                </ListItem>
                <ListItem>
                  <ListItemText primary={'Contact number: ' + userData?.phoneNumber} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={'Email: ' + userData?.email} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={'Full Name: ' + userData?.firstName + ' ' + userData?.lastName} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={'Username: ' + userData?.username} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={'Inviter: ' + userData?.inviter?.username} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={'Inviter Contact: ' + userData?.inviter?.email} />
                </ListItem>
                {userData.uid === auth.currentUser.uid ? (
                  <ListItem>
                    <ListItemText primary={'Gift Status: ' + userData?.status} />
                  </ListItem>
                ) : (
                  ''
                )}
              </List>
              {userData?.status !== 'approved' && (
                <div className="ms-3 mb-3">
                  {userData?.status === 'pending' && auth.currentUser.uid === boardData?.lagend?.uid ? (
                    <>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => changeGifterStatus(userData, 'approved')}
                        className="text-white"
                      >
                        Confirm
                      </Button>
                      {/* <Button
                        onClick={() => changeGifterStatus(userData, 'rejected')}
                        variant="contained"
                        className="mx-3"
                        color="error"
                      >
                        Reject
                      </Button> */}
                    </>
                  ) : (
                    userData?.status === 'rejected' && <Button color="error">Rejected</Button>
                  )}
                </div>
              )}
              {userData && (
                <div className="ms-3 mb-3">
                  {auth.currentUser.uid === userData.uid ? (
                    <>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => quitGiftProsperity(userData)}
                        className="text-white"
                      >
                        Quit Gift Prosperity
                      </Button>
                      {/* <Button
                        onClick={() => changeGifterStatus(userData, 'rejected')}
                        variant="contained"
                        className="mx-3"
                        color="error"
                      >
                        Reject
                      </Button> */}
                    </>
                  ) : (
                    userData?.status === 'rejected' && <Button color="error">Rejected</Button>
                  )}
                </div>
              )}
            </Popover>
          )}
          {warning ? (
            <Alert className="my-2" variant="filled" severity="error">
              You must send the gift to the legend within 48 hours of joining and ask the legend to confirm it. Failure
              to do so will result in automatic removal from the board.{' '}
            </Alert>
          ) : (
            ''
          )}
          <div className="d-flex row my-3 border p-4 rounded">
            <div className="col-md-4 text-md-start text-center">
              <Typography variant="h4">
                Left Hemisphere: {boardData?.leftGifters?.length >= 2 ? 'Closed' : 'Open'}
              </Typography>
              <Typography>Gifters 1-2 enters on left hemisphere</Typography>
            </div>
            <div className="col-md-4 text-center">
              <Typography variant="h3">{boardData?.boardName}</Typography>
              <Typography>Board Created on {boardData?.createdOn?.toDate().toLocaleString()}</Typography>
              <Typography>Board ID: {boardData?.id}</Typography>
            </div>
            <div className="col-md-4 text-md-end text-center">
              <Typography variant="h4">
                Right Hemisphere: {boardData?.rightGifters?.length >= 2 ? 'Closed' : 'Open'}
              </Typography>
              <Typography>Gifters 3-4 enters on right hemisphere</Typography>
            </div>
          </div>

          <div className="container-fluid px-5 ">
            <div className="row d-flex justify-content-between">
              <div className="col-md-1 text-center">
                <Typography variant="h5">Gifter</Typography>
                <div className="col-12 d-flex flex-wrap align-items-center h-100 justify-content-between">
                  {boardData?.leftGifters?.map((gifter, index) => {
                    return (
                      <div className="col-md-12 col  my-5" key={index}>
                        <Button
                          onClick={(event) => {
                            handleClick(event);
                            gifter.index = index;
                            setUserData(gifter);
                          }}
                          variant="outlined"
                          color="info"
                          style={{ borderRadius: '50%', height: '100px', width: '100px' }}
                        >
                          {gifter.username}
                        </Button>
                      </div>
                    );
                  })}

                  {/* If gifters are not full */}

                  {!boardData?.leftGifters || boardData?.leftGifters?.length < 2 ? (
                    <>
                      {Array.from({ length: 2 - (boardData?.leftGifters?.length || 0) }, (_, i) => (
                        <div className="col-md-12 col my-5" key={i}>
                          <Button
                            onClick={(event) => {
                              handleClick(event);
                              setUserData({});
                            }}
                            variant="outlined"
                            disabled={true}
                            style={{ borderRadius: '50%', height: '100px', width: '100px' }}
                          >
                            {'Gifter'}
                          </Button>
                        </div>
                      ))}
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div className="col-md-1 text-center">
                <Typography variant="h5">Guide</Typography>
                <div className="col-12 d-flex flex-wrap align-items-center h-100 justify-content-between">
                  <div className="col-md-12 col  my-5">
                    <Button
                      onClick={(event) => {
                        handleClick(event);
                        setUserData(boardData?.leftGuide);
                      }}
                      disabled={boardData?.leftGuide?.username ? false : true}
                      variant="contained"
                      color="warning"
                      style={{ borderRadius: '50%', height: '100px', width: '100px' }}
                    >
                      {boardData?.leftGuide?.username || 'Guide'}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-md-1 text-center ">
                <Typography variant="h5">Legend</Typography>
                <div className="col-12 d-flex flex-wrap align-items-center h-100 justify-content-between">
                  <div className="col-md-12 col  my-5">
                    <Button
                      onClick={(event) => {
                        handleClick(event);
                        setUserData(boardData?.lagend);
                      }}
                      variant="contained"
                      style={{ borderRadius: '50%', height: '100px', width: '100px' }}
                    >
                      {boardData?.lagend?.username}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-md-1 text-center">
                <Typography variant="h5">Guide</Typography>
                <div className="col-12 d-flex flex-wrap align-items-center h-100 justify-content-between">
                  <div className="col-md-12 col  my-5">
                    <Button
                      onClick={(event) => {
                        handleClick(event);
                        setUserData(boardData?.rightGuide);
                      }}
                      disabled={boardData?.rightGuide?.username ? false : true}
                      variant="contained"
                      color="warning"
                      style={{ borderRadius: '50%', height: '100px', width: '100px' }}
                    >
                      {boardData?.rightGuide?.username || 'Guide'}
                    </Button>
                  </div>
                </div>
              </div>

              <div className="col-md-1 text-center">
                <Typography variant="h5">Gifter</Typography>
                <div className="col-12 d-flex flex-wrap align-items-center h-100 justify-content-between">
                  {boardData?.rightGifters?.map((gifter, index) => {
                    return (
                      <div className="col-md-12 col  my-5" key={index}>
                        <Button
                          onClick={(event) => {
                            handleClick(event);
                            gifter.index = index;
                            setUserData(gifter);
                          }}
                          variant="outlined"
                          color="info"
                          style={{ borderRadius: '50%', height: '100px', width: '100px' }}
                        >
                          {gifter.username}
                        </Button>
                      </div>
                    );
                  })}

                  {/* If gifters are not full */}

                  {!boardData?.rightGifters || boardData?.rightGifters?.length < 2 ? (
                    <>
                      {Array.from({ length: 2 - (boardData?.rightGifters?.length || 0) }, (_, i) => (
                        <div className="col-md-12 col my-5" key={i}>
                          <Button
                            onClick={(event) => {
                              handleClick(event);
                              setUserData({});
                            }}
                            variant="outlined"
                            disabled={true}
                            style={{ borderRadius: '50%', height: '100px', width: '100px' }}
                          >
                            {'Gifter'}
                          </Button>
                        </div>
                      ))}
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BoardPage;
