import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import AppSlider from 'src/sections/@dashboard/app/AppSlider';
import Alert from '@mui/material/Alert';
import { doc, getDoc, getDocs, query, where, orderBy, limit, collection, updateDoc, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { getAuth } from 'firebase/auth';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from 'react';

// ----------------------------------------------------------------------
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function DashboardAppPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [price, setPrice] = React.useState(0);
  const [board, setBoard] = React.useState(0);
  const [isJoining, setIsJoining] = React.useState(false);
  const [targetBoard, setTargetBoard] = React.useState(0);
  const [userData, setUserData] = React.useState({});
  const auth = getAuth();

  useEffect(() => {
    getDoc(doc(db, 'users', auth.currentUser.uid)).then((user) => {
      setUserData(user.data());
    });

    // getDoc(doc(db,'diamondBoards','jvhYiO5BmxZw1OxfAfFn')).then((data)=>{
    //   console.log("Data",data.data());
    //   addDoc(collection(db, 'vipBoardsIII'), data.data())
    //   .then((docRef) => {
    //     console.log("Document written with ID: ", docRef.id);
    //   })
    //   .catch((err) => {
    //     console.error("Error adding document: ", err);
    //   });
    // })
  }, [auth.currentUser.uid]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const crossPollination = async (board) => {
    // Create a query to find boards where participants array contains the user's UID
    const boardQuery = query(collection(db, board), where('participants', 'array-contains', auth.currentUser.uid));

    // Execute the query
    const querySnapshot = await getDocs(boardQuery);

    // Check if any documents match the query
    if (!querySnapshot.empty) {
      toast.error('You are already member of this board.Reload a page or concat support.');
      return;
    }
    console.log('Cross Polination');
    const collectionRef = collection(db, board);
    const boardquery = query(
      collectionRef,
      where('totalMembers', '<', 7),
      orderBy('totalMembers', 'asc'),
      orderBy('createdOn', 'asc'),
      limit(1)
    );
    var foundDocument = false;
    getDocs(boardquery)
      .then((querySnapshot) => {
        console.log('result');
        querySnapshot.forEach(async (docData) => {
          console.log(foundDocument);
          if (!foundDocument) {
            // Check if we haven't found a matching document yet
            const data = docData.data();
            if (data.participants && data.participants.length < 7) {
              const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
              const UserData = {
                uid: userDoc.data().uid,
                email: userDoc.data().email,
                firstName: userDoc.data().firstName,
                lastName: userDoc.data().lastName,
                inviter: userDoc.data().inviter,
                phoneNumber: userDoc.data().phoneNumber,
                username: userDoc.data().username,
                boardJoiningDate: new Date(),
                status: 'pending',
              };
              if (data.leftGifters.length < 2) {
                UserData.side = 'left';
                data.leftGifters.push(UserData);
                data.participants.push(UserData.uid);
                data.totalMembers = data.participants.length;
                updateDoc(doc(db, board, docData.id), {
                  participants: data.participants,
                  leftGifters: data.leftGifters,
                  totalMembers: data.totalMembers,
                })
                  .then(() => {
                    setIsJoining(false);
                    addDoc(collection(db, 'removeUsers'), {
                      board: docData.id,
                      user: UserData.uid,
                      status: 'pending',
                      boardName: board,
                      time: new Date(),
                    })
                      .then(() => {
                        navigate('/dashboard/board/' + board);
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  })
                  .catch((error) => {
                    setIsJoining(false);
                    toast.error('Error.Try Again');
                    console.log('Error', error);
                  });
              } else if (data.rightGifters.length < 2) {
                UserData.side = 'right';
                data.rightGifters.push(UserData);
                data.participants.push(UserData.uid);
                data.totalMembers = data.participants.length;

                updateDoc(doc(db, board, docData.id), {
                  participants: data.participants,
                  rightGifters: data.rightGifters,
                  totalMembers: data.totalMembers,
                })
                  .then(() => {
                    setIsJoining(false);
                    addDoc(collection(db, 'removeUsers'), {
                      board: docData.id,
                      user: UserData.uid,
                      status: 'pending',
                      boardName: board,
                      time: new Date(),
                    })
                      .then(() => {
                        navigate('/dashboard/board/' + board);
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  })
                  .catch((error) => {
                    setIsJoining(false);
                    toast.error('Error.Try Again');
                    console.log('Error', error);
                  });
                foundDocument = true;
              } else {
                toast.error('We are unable to find a board for you try again after few mins.');
              }

              // Set the flag to true to stop further searching
            }
          } else {
            toast.error('Not able to ad you in a board try again after few mins.');
          }
        });
      })
      .catch((error) => {
        console.error('Error getting documents: ', error);
      });
  };

  const goToBoard = async (price, board, link, target) => {
    if (target != 'bronze' && !userData?.userBoards?.includes(target)) {
      toast.error('Board is Locked');
      return;
    }
    try {
      // Create a query to find boards where participants array contains the user's UID
     // const boardQuery = query(collection(db, link), where('participants', 'array-contains', ));
      const boardQuery = query(
        collection(db, link),
        where('participants', 'array-contains', auth.currentUser.uid),
        orderBy('createdOn', 'desc'), // Order by the createdOn field in descending order
        limit(1) // Get only the latest document
      );
      // Execute the query
      const querySnapshot = await getDocs(boardQuery);

      // Check if any documents match the query
      if (!querySnapshot.empty) {
        navigate('/dashboard/board/' + link);
      } else {
        setPrice(price);
        setTargetBoard(link);
        setBoard(board);
        handleClickOpen();

        console.log('Not a existing member');
      }
    } catch (error) {
      toast.error('Error Board Not Found.Contact Support');
      console.error('Error fetching boards:', error);
    }
  };

  const addLeftSide =async (UserData,invitedBoard,boardId,targetBoard)=>{

    UserData.side = 'left';
    invitedBoard.leftGifters.push(UserData);
    invitedBoard.participants.push(UserData.uid);
    invitedBoard.totalMembers = invitedBoard.participants.length;

    updateDoc(doc(db, targetBoard, boardId), {
      participants: invitedBoard.participants,
      leftGifters: invitedBoard.leftGifters,
      totalMembers: invitedBoard.totalMembers,
    })
      .then(() => {
        setIsJoining(false);
        addDoc(collection(db, 'removeUsers'), {
          board: boardId,
          user: UserData.uid,
          boardName: targetBoard,
          status: 'pending',
          time: new Date(),
        })
          .then(() => {
            navigate('/dashboard/board/' + targetBoard);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        setIsJoining(false);
        toast.error('Error.Try Again');
        console.log('Error', error);
      });
  }

  const addRight =async (UserData,invitedBoard,boardId,targetBoard)=>{
    UserData.side = 'right';
    invitedBoard.rightGifters.push(UserData);
    invitedBoard.participants.push(UserData.uid);
    invitedBoard.totalMembers = invitedBoard.participants.length;

    updateDoc(doc(db, targetBoard, boardId), {
      participants: invitedBoard.participants,
      rightGifters: invitedBoard.rightGifters,
      totalMembers: invitedBoard.totalMembers,
    })
      .then(() => {
        setIsJoining(false);
        addDoc(collection(db, 'removeUsers'), {
          board: boardId,
          user: UserData.uid,
          status: 'pending',
          boardName: targetBoard,
          time: new Date(),
        })
          .then(() => {
            navigate('/dashboard/board/' + targetBoard);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        setIsJoining(false);
        toast.error('Error.Try Again');
        console.log('Error', error);
      });
  }
  const joinBoard = async () => {
    try {
      setIsJoining(true);

      const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
      const inviterId = userDoc.data().inviter.uid;
      const UserData = {
        uid: userDoc.data().uid,
        email: userDoc.data().email,
        firstName: userDoc.data().firstName,
        lastName: userDoc.data().lastName,
        inviter: userDoc.data().inviter,
        phoneNumber: userDoc.data().phoneNumber,
        username: userDoc.data().username,
        boardJoiningDate: new Date(),
        status: 'pending',
      };

      // Create a query to find boards where participants array contains the user's UID
      const boardQuery = query(
        collection(db, targetBoard),
        where('participants', 'array-contains', inviterId),
        orderBy('createdOn', 'desc'), // Order by the createdOn field in descending order
        limit(1) // Get only the latest document
      );
      // Execute the query
      const querySnapshot = await getDocs(boardQuery);

      // Check if any documents match the query
      if (!querySnapshot.empty) {
        const invitedBoard = querySnapshot.docs[0].data();
        const boardId = querySnapshot.docs[0].id;
        if (!invitedBoard.leftGifters) {
          invitedBoard.leftGifters = [];
        }
        if (!invitedBoard.rightGifters) {
          invitedBoard.rightGifters = [];
        }
        if (invitedBoard.participants.length >= 7) {
          const userConfirmed = window.confirm(
            `The ${board} of your inviter is full. Click on Confirm to join a random ${board}.`
          );
          if (userConfirmed) {
            crossPollination(targetBoard);
          } else {
            setIsJoining(false);
          }
        } else {
          //User not joining the board to testing and debugging.
         // Check if the length conditions for leftGifters and rightGifters are met
const isLeftGiftersValid = invitedBoard.leftGifters.length < 2;
const isRightGiftersValid = invitedBoard.rightGifters.length < 2;

var addedOnLeft=false;
if (isLeftGiftersValid) {
    // Check specific conditions for adding to the left side
    if ((invitedBoard?.leftGuide?.uid == inviterId) || 
        (invitedBoard?.lagend?.uid == inviterId) || 
        (invitedBoard.leftGifters[0]?.uid == inviterId)) {
        addedOnLeft=true;
        addLeftSide(UserData, invitedBoard, boardId, targetBoard);
    }
}

if (isRightGiftersValid && !addedOnLeft) {
    // Check specific conditions for adding to the right side
    if ((invitedBoard?.rightGuide?.uid == inviterId) || 
        (invitedBoard?.lagend?.uid == inviterId) || 
        (invitedBoard.rightGifters[0]?.uid == inviterId)) {
        addRight(UserData, invitedBoard, boardId, targetBoard);
    }
}
 else {
            const userConfirmed = window.confirm(
              'The Side of your inviter is full. Click on Confirm to join a random board.'
            );
            if (userConfirmed) {
              crossPollination(targetBoard);
            } else {
              setIsJoining(false);
            }
          }
        }
      } else {
        const userConfirmed = window.confirm(
          `Your inviter is not in any ${board}. Click on Confirm to join a random ${board}.`
        );
        if (userConfirmed) {
          crossPollination(targetBoard);
        } else {
          setIsJoining(false);
        }
        console.log('No matching boards found.');
      }
    } catch (error) {
      setIsJoining(false);
      toast.error('Error Board Not Found. Contact Support');
      console.error('Error fetching boards:', error);
    }
  };

  return (
    userData && (
      <>
        <Helmet>
          <title> Dashboard | Gift Prosperity </title>
        </Helmet>

        <Container maxWidth="xl">
          <div className=" d-flex justify-content-between">
            <div className="col-12 text-start">
              <Typography variant="h4" sx={{ mb: 5 }}>
                Hi,{userData?.firstName} Welcome back
              </Typography>
            </div>
          </div>
          {userData?.invites?.length < 2 || !userData.invites ? (
            <Alert className="my-2" variant="filled" severity="error">
              You have not invited 2 people that have gifted and will not be able to progress after your first bronze
              board.
            </Alert>
          ) : (
            ''
          )}
          <AppSlider className="mb-5" />
          <Grid className="my-5 justify-content-center" container spacing={3}>
            <Grid item xs={12} className="text-center w-100 d-flex">
              <img className="m-auto" src="/assets/images/horizontalb.png" height={60} alt="" srcset="" />
            </Grid>
            <Grid
              onClick={() => {
                goToBoard(5, 'Bronze Board', 'bronzeBoards', 'bronze');
              }}
              role="button"
              item
              xs={12}
              sm={6}
              md={3}
            >
              <AppWidgetSummary
                title="Bronze"
                subtitle="$5 Gift Value"
                bgImage={'url("/assets/images/imagebr.png")'}
                total={1352831}
                color="info"
                icon={'carbon:view-filled'}
              />
            </Grid>

            <Grid
              onClick={() => {
                goToBoard(15, 'Silver Board', 'silverBoards', 'silver');
              }}
              role="button"
              item
              xs={12}
              sm={6}
              md={3}
            >
              <AppWidgetSummary
                title="Silver"
                subtitle="$15 Gift Value"
                bgImage={'url("/assets/images/imagesl.png")'}
                total={4}
                color="error"
                icon={userData?.userBoards?.includes('silver') ? 'carbon:view-filled' : 'fontisto:locked'}
              />
            </Grid>
            <Grid
              onClick={() => {
                goToBoard(40, 'Gold Board', 'goldBoards', 'gold');
              }}
              role="button"
              item
              xs={12}
              sm={6}
              md={3}
            >
              <AppWidgetSummary
                title="Gold"
                subtitle="$40 Gift Value"
                bgImage={'url("/assets/images/imageg.png")'}
                total={4}
                color="error"
                icon={userData?.userBoards?.includes('gold') ? 'carbon:view-filled' : 'fontisto:locked'}
              />
            </Grid>
            <Grid
              onClick={() => {
                goToBoard(100, 'Platinum Board', 'platinumBoards', 'platinum');
              }}
              role="button"
              item
              xs={12}
              sm={6}
              md={3}
            >
              <AppWidgetSummary
                title="Platinum"
                subtitle="$100 Gift Value"
                bgImage={'url("/assets/images/imagept.png")'}
                total={4}
                color="error"
                icon={userData?.userBoards?.includes('platinum') ? 'carbon:view-filled' : 'fontisto:locked'}
              />
            </Grid>
            <Grid
              onClick={() => {
                goToBoard(200, 'Diamond Board', 'diamondBoards', 'diamond');
              }}
              role="button"
              item
              xs={12}
              sm={6}
              md={3}
            >
              <AppWidgetSummary
                title="Diamond"
                subtitle="$200 Gift Value"
                bgImage={'url("/assets/images/imagedm.png")'}
                total={4}
                color="error"
                icon={userData?.userBoards?.includes('diamond') ? 'carbon:view-filled' : 'fontisto:locked'}
              />
            </Grid>
            <Grid
              onClick={() => {
                goToBoard(400, 'VIP Board', 'vipBoards', 'vip');
              }}
              role="button"
              item
              xs={12}
              sm={6}
              md={3}
            >
              <AppWidgetSummary
                title="VIP"
                subtitle="$400 Gift Value"
                bgImage={'url("/assets/images/imagevip.png")'}
                total={4}
                color="error"
                icon={userData?.userBoards?.includes('vip') ? 'carbon:view-filled' : 'fontisto:locked'}
              />
            </Grid>
            <Grid
              onClick={() => {
                goToBoard(1000, 'VIP Board II', 'vipBoardsII', 'vipii');
              }}
              role="button"
              item
              xs={12}
              sm={6}
              md={3}
            >
              <AppWidgetSummary
                title="VIP II"
                subtitle="$1000 Gift Value"
                bgImage={'url("/assets/images/imagevip.png")'}
                total={4}
                color="error"
                icon={userData?.userBoards?.includes('vipii') ? 'carbon:view-filled' : 'fontisto:locked'}
              />
            </Grid>
            <Grid
              onClick={() => {
                goToBoard(2000, 'VIP Board III', 'vipBoardsIII', 'vipiii');
              }}
              role="button"
              item
              xs={12}
              sm={6}
              md={3}
            >
              <AppWidgetSummary
                title="VIP III"
                subtitle="$2000 Gift Value"
                bgImage={'url("/assets/images/imagevip.png")'}
                total={4}
                color="error"
                icon={userData?.userBoards?.includes('vipiii') ? 'carbon:view-filled' : 'fontisto:locked'}
              />
            </Grid>
            <Grid item xs={12} className="text-center w-100 d-flex">
              <img className="m-auto" src="/assets/images/horizontalb.png" height={50} alt="" srcset="" />
            </Grid>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{'Join New Board'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  You are joining a new {board}. The Gift value is<b> ${price}.</b> Please ensure your gift is ready to
                  be gifted to the Legend BEFORE you click the confirm button.
                  <br />
                  <br />
                  <br />
                  As a Gifter, it is your responsibility to contact the Legend as soon as possible. No communication
                  within <b>48 hours</b> could potentially lead to board removal.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" disabled={isJoining} className="bg-danger" onClick={handleClose}>
                  Cancle
                </Button>
                <Button
                  disabled={isJoining}
                  variant="contained"
                  onClick={() => {
                    joinBoard();
                  }}
                >
                  {isJoining ? <CircularProgress className="text-white" size={27} /> : ' Confirm'}
                </Button>
              </DialogActions>
            </Dialog>
            {/*
          <Grid role="button" item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Website Visits"
              subheader="(+43%) than last year"
              chartLabels={[
                '01/01/2003',
                '02/01/2003',
                '03/01/2003',
                '04/01/2003',
                '05/01/2003',
                '06/01/2003',
                '07/01/2003',
                '08/01/2003',
                '09/01/2003',
                '10/01/2003',
                '11/01/2003',
              ]}
              chartData={[
                {
                  name: 'Team A',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Team B',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'Team C',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Current Visits"
              chartData={[
                { label: 'America', value: 4344 },
                { label: 'Asia', value: 5435 },
                { label: 'Europe', value: 1443 },
                { label: 'Africa', value: 4443 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
          </Grid>
        </Container>
      </>
    )
  );
}
