import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Button } from '@mui/material';
import Iconify from 'src/components/iconify/Iconify';
import { Link } from 'react-router-dom';

export default function SwitchLabels() {
  return (
    <>
      <Link to="/dashboard/app">
        <Button>Boards</Button>
      </Link>
      <Link to="https://linktr.ee/giftprosperity" target="_blank">
        <Button>Academy</Button>
      </Link>
      {/* <Link to="/dashboard/settings">
        {' '}
        <Button>Personal Info</Button>
      </Link> */}
      <a href="https://t.me/GiftprosperityOficial" target="_blank">
        <Iconify style={{ width: '35px', height: '35px' }} icon={'logos:telegram'} />
      </a>
    </>
  );
}
