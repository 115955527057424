import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller, useFormContext } from 'react-hook-form';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import Typography from 'src/theme/overrides/Typography';

// ----------------------------------------------------------------------
import { db, auth } from '../../../firebase';
import { toast } from 'react-toastify';
import { getDocs, collection, query, where, doc, setDoc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from 'firebase/auth';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from 'src/Context/AuthContext';
export default function SignupForm() {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [isProcessing, setisProcessing] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [agreeCustomerSupport, setAgreeCustomerSupport] = useState(false);
  const [isTermsRequired, setIsTermsRequired] = useState(false);
  const [isCustomerSupportRequired, setIsCustomerSupportRequired] = useState(true);
  const [inviter, setInviter] = useState();
  const { invite } = useParams();
  useEffect(() => {
    if (invite) {
      // Create a reference to the document with the provided invite as the document ID
      const userDocRef = doc(db, 'invitationLinks', invite);

      // Get the document data
      getDoc(userDocRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            const currentTime = new Date().getTime();
            const docData = docSnapshot.data();

            if (currentTime < docData?.expiryTime?.seconds) {
              toast.error('Invitation Link is Expired or already Used.');
              setInviter({ error: 'Invitation Link is Expired or already Used' });
            } else {
              // Document exists, you can access the data using docSnapshot.data()
              setInviter(docData.inviter);
            }
          } else {
            toast.error('Invitation Link is Expired or already Used');

            setInviter({ error: 'Invitation Link is Expired or already Used' });
          }
        })
        .catch((error) => {
          console.error('Error getting invite document:', error);
        });
    }
    setIsTermsRequired(true);
  }, []);

  const functions = getFunctions();
  const { login } = useAuth();
  const addNewUser = async (authData) => {
    try {
      // Create a new user with the provided auth data, including display name
      const userCredential = await createUserWithEmailAndPassword(auth, authData.email, authData.password);
      const user = userCredential.user;
      console.log('User Credentials', userCredential);

      // Set the display name for the user
      const displayName = `${authData.firstName} ${authData.lastName}`;
      await updateProfile(user, { displayName });

      // Send email verification to the current user
      await sendEmailVerification(user);
      console.log('Verification email sent.');

      // Return the user data

      return user;
    } catch (error) {
      // Handle any errors that occurred during the process
      console.error('Error creating user or sending verification email:', error);
      throw error;
    }
  };

  const onSubmit = async (data) => {
    var userStatus = await checkUsernameExists(data.username);
    if (userStatus) {
      toast.error('Username already taken.');
      return;
    }
    if (isTermsRequired && !agreeTerms) {
      // Terms and Conditions checkbox is required but not checked
      // Display an error message or prevent form submission
      alert('Please agree to the Terms and Conditions.');
      return;
    }

    if (isCustomerSupportRequired && !agreeCustomerSupport) {
      // Customer Support checkbox is required but not checked
      // Display an error message or prevent form submission
      alert('Please agree to receive emails about Customer Support.');
      return;
    }
    // Omit the password and confirmPassword fields from the Firestore document data
    const userData = {
      firstName: data.firstName,
      lastName: data.lastName,
      username: data.username,
      email: data.email,
      phoneNumber: data.phoneNumber,
      giftStatus: 'pending',
      userBoards: ['bronze'],
    };

    // To add in Firebase Authentication
    const authData = {
      email: data.email,
      password: data.password,
      firstName: data.firstName,
      lastName: data.lastName,
    };

    // Set isProcessing to true to indicate that the form is being processed
    setisProcessing(true);

    // Create the user in Firebase Authentication
    addNewUser(authData)
      .then((user) => {
        userData.uid = user.uid;
        userData.joinedOn = new Date();
        if (inviter) {
          userData.inviter = { uid: inviter.uid, email: inviter.email, username: inviter.username };
        }

        console.log('User Data', userData);
        // Add user data to Firestore and update the inviter
        setDoc(doc(db, 'users', user.uid), userData)
          .then(() => {
            if (inviter) {
              updateInviter(inviter, userData)
                .then(() => {
                  auth
                    .signOut()
                    .then(() => {
                      // Reset isProcessing to false, show success message, and reset the form
                      setisProcessing(false);
                      toast.success('Account Created.Verify Your Email to Login');
                      navigate('/login');
                      deleteDoc(doc(db, 'invitationLinks', invite))
                        .then(() => {
                          console.log('Document successfully deleted');
                        })
                        .catch((error) => {
                          console.error('Error deleting document: ', error);
                        });
                      reset();
                    })
                    .catch((error) => {
                      console.log('Error Whie logging out', error);
                    });
                })
                .catch((error) => {
                  console.log('Error While Updating Inviter', error);
                });
            } else {
              // Reset isProcessing to false, show success message, and reset the form
              setisProcessing(false);
              toast.success('Account Created.Verify Your Email to Login');
              navigate('/login');
              reset();
            }
          })
          .catch((error) => {
            // Handle Firestore data insertion error
            setisProcessing(false);
            console.error('Error adding user data to Firestore:', error);
            toast.error('Error creating account. Please try again later.');
          });
      })
      .catch((error) => {
        console.log('In catch Error');
        // Handle Firebase Authentication error
        setisProcessing(false);
        console.error('Error creating user in Firebase:', error);
        switch (error.code) {
          case 'auth/internal-error': {
            toast.error('Error! Please Try Again.');
            break;
          }
          case 'auth/invalid-password': {
            toast.error('Invalid Password.');
            break;
          }
          case 'auth/wrong-password': {
            toast.error('Invalid Password.');
            break;
          }
          case 'auth/invalid-email': {
            toast.error('No User Found With This Email.');
            break;
          }
          case 'auth/session-cookie-expired': {
            toast.error('Session Expired Please Login Again.');
            break;
          }
          case 'auth/user-not-found': {
            toast.error('User Not Found.');
            break;
          }
          case 'auth/invalid-credential': {
            toast.error('Invalid Credentials.');
            break;
          }
          case 'auth/user-disabled': {
            toast.error('Your Account Has Been Disabled.');
            break;
          }
          case 'auth/email-already-in-use': {
            toast.error('An account with this email already exists.');
            break;
          }
          default: {
            toast.error(error.message);
          }
        }
      });
  };
  // Function to update the inviter document with the new user data
  const updateInviter = async (inviterDoc, newUserData, customValue) => {
    try {
      const updatedInvites = inviterDoc.invites ? [...inviterDoc.invites, newUserData] : [newUserData];
      await updateDoc(doc(db, 'users', inviterDoc.uid), {
        invites: updatedInvites,
        uid: inviterDoc.uid,
      });
    } catch (error) {
      console.error('Error updating inviter document:', error);
      throw error;
    }
  };

  // Function to check if a username already exists in Firestore
  const checkUsernameExists = async (username) => {
    const usersCollection = collection(db, 'users');
    const usernameQuery = query(usersCollection, where('username', '==', username));
    const usernameSnapshot = await getDocs(usernameQuery);
    return !usernameSnapshot.empty;
  };
  return (
    <>
      {inviter ? (
        <>
          {invite && (!inviter || !inviter?.error) ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3}>
                {invite && (
                  <>
                    {invite && (!inviter || !inviter?.error) ? (
                      <Alert variant="outlined" severity="success">
                        Your are invited by {inviter?.username}
                      </Alert>
                    ) : (
                      ''
                    )}
                  </>
                )}

                <Controller
                  name="firstName"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'First Name is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="First Name"
                      error={!!errors.firstName}
                      helperText={errors.firstName?.message}
                    />
                  )}
                />

                <Controller
                  name="lastName"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Last Name is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Last Name"
                      error={!!errors.lastName}
                      helperText={errors.lastName?.message}
                    />
                  )}
                />

                <Controller
                  name="username"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: 'Username is required',
                    minLength: { value: 6, message: 'Username must be at least 6 characters long' },
                    pattern: {
                      value: /^[a-z0-9]+$/,
                      message: 'Username must contain only lowercase letters and numbers without any space',
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Username"
                      error={!!errors.username}
                      helperText={errors.username?.message}
                    />
                  )}
                />

                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: 'Email is required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address',
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Email address"
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  )}
                />

                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: 'Phone Number is required',
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Phone Number"
                      error={!!errors.phoneNumber}
                      helperText={errors.phoneNumber?.message}
                    />
                  )}
                />

                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: 'Password is required',
                    minLength: { value: 8, message: 'Password must be at least 8 characters long' },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                              <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                <Controller
                  name="confirmPassword"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: 'Confirm Password is required',
                    validate: (value) => value === watch('password') || 'Passwords do not match',
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Confirm Password"
                      type={showPassword ? 'text' : 'password'}
                      error={!!errors.confirmPassword}
                      helperText={errors.confirmPassword?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                              <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <div>
                  <Checkbox
                    name="termsAndConditions"
                    checked={agreeTerms}
                    onChange={() => setAgreeTerms(!agreeTerms)}
                    required={isTermsRequired}
                  />
                  <span>
                    I agree to the{' '}
                    <a target="_blank" href="/terms_and_conditions">
                      {' '}
                      Terms and Conditions
                    </a>
                  </span>
                </div>
              </Stack>
              <div>
                <span className="ms-2 ps-1">I agree to receive emails about the following:</span>
                <div>
                  <Checkbox
                    name="customerSupport"
                    checked={agreeCustomerSupport}
                    onChange={() => setAgreeCustomerSupport(!agreeCustomerSupport)}
                    required={isCustomerSupportRequired}
                  />
                  <span> Customer Support (Required)</span>
                </div>

                <div>
                  <Checkbox name="boardActivity" />
                  <span>Board Activity (Optional)</span>
                </div>
                <div>
                  <Checkbox name="marketing" />
                  <span>Marketing (Optional)</span>
                </div>
              </div>
              <LoadingButton
                disabled={isProcessing}
                fullWidth
                className="mt-4"
                size="large"
                type="submit"
                variant="contained"
              >
                {isProcessing ? <CircularProgress className="text-white" size={27} /> : 'Singn Up'}
              </LoadingButton>
            </form>
          ) : (
            <Alert variant="outlined" severity="error">
              The invitation link that you are using does not exist.
            </Alert>
          )}
        </>
      ) : (
        <p className="text-danger">Please Wait we are verifying the invitation Link...</p>
      )}
    </>
  );
}
