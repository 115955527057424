// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(5),

  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({ title, total, icon, bgImage, subtitle, color = 'primary', sx, ...other }) {
  return (
    <Card
      sx={{
        py: 5,
        border: 'none',
        boxShadow: 0,
        background: 'transparent',
        textAlign: 'center',
        position: 'relative', // Make the card a positioned container
        ...sx,
      }}
      {...other}
    >
      <div
        style={{
          backgroundImage: bgImage,
          // backgroundImage: 'url("/assets/images/image.png")',
          backgroundSize: 'contain', // Fit the entire image within the container
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat', // Prevent image from repeating
          position: 'absolute', // Position the image absolutely within the card
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1, // Place the image behind other content
        }}
      ></div>

      <StyledIcon>
        <Iconify  icon={icon} width={45} height={45} />
      </StyledIcon>

      <Typography  variant="h5">
        {title}
      </Typography>
      <small>{subtitle}</small>
    </Card>
  );
}
