import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//

import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';

import BoardPage from './pages/BoardPage';
import Settings from './pages/Settings';
import Chat from './pages/Chat';

import SingUp from './pages/SignUp';
// ----------------------------------------------------------------------
//context
import ProtectedRoute from './Context/ProtectedRoute';
import LogoutRoute from './pages/LogoutRoute';
import TermsAndConditions from './pages/TermsAndConditions';
export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'board/:board', element: <BoardPage /> },
        // { path: 'addadmin', element: <AddAdmin /> },
        // { path: 'category', element: <AddCategory /> },
        // { path: 'companies', element: <Companies /> },
        // { path: 'courses', element: <BlogPage /> },
        // { path: 'addcourses/:DocId?', element: <AddCourse /> },
        // { path: 'user', element: <UserPage /> },
        { path: 'chat/:board', element: <Chat /> },
        { path: 'settings', element: <Settings /> },
        // { path: 'products', element: <ProductsPage /> },
        // { path: 'couredetails', element: <CourseContent /> },
        // { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'logout',
      element: <LogoutRoute />,
    },
    {
      path: 'terms_and_conditions',
      element: <TermsAndConditions />,
    },
    {
      path: 'signup',
      element: <SingUp />,
    },
    {
      path: 'signup/:invite',
      element: <SingUp />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
