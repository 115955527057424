import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const images = [
  // '/assets/images/welcome.jpg',
  // '/assets/images/noJumping.jpg',
  // '/assets/images/assistVerification.jpg',
  // '/assets/images/bePatient.jpg',
  // '/assets/images/bePatient.jpg',
  // '/assets/images/getVerified.jpg',
  // '/assets/images/secureWith2FA.jpg',
  // '/assets/images/shareLink.jpg',
  // '/assets/images/support.jpg',
  // '/assets/images/updateInfo.jpg',
  // '/assets/images/limitedBoardView.jpg',
  '/assets/images/1.jpeg',
  '/assets/images/2.jpeg',
  '/assets/images/3.jpeg',

  // '/assets/images/slide2.jpg',
  // '/assets/images/slide3.png',
];

const AppSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true, // Center the current slide horizontally
    arrows: true, // Show left/right arrows
    autoplay: true, // Enable auto-sliding
    autoplaySpeed: 4000, // Auto-sliding interval (4 seconds)
  };

  return (
    <div>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img className="w-100 px-5" src={image} alt={`Image ${index}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default AppSlider;
