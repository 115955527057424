import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import Iconify from 'src/components/iconify/Iconify';
import { toast } from 'react-toastify';
import { getAuth } from 'firebase/auth';
import { getDoc, doc, collection, addDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  color: '#fff',
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.logo[0], 1),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const [user, setUser] = useState('');

  const [inviteLink, setInviteLink] = useState('');
  const generateInvitationLink = async () => {
    if (user?.giftStatus != 'confirmed') {
      toast.error("Before inviting your friends, please make sure you've sent at least one gift.");

      return;
    }

    if (!user) {
      toast.error('Error Try again.');
      return;
    }

    // Get the current time
    const currentTime = new Date();

    // Set the expiry time to be 24 hours from now
    const expiryTime = new Date();
    expiryTime.setHours(currentTime.getHours() + 24);

    // Create the invitation object with the inviter, generatedOn, and expiryTime
    const invitation = {
      inviter: user,
      generatedOn: currentTime,
      expiryTime: expiryTime,
    };

    // Reference the 'invitationLinks' collection and add the document
    addDoc(collection(db, 'invitationLinks'), invitation)
      .then((docRef) => {
        console.log('Document written with ID: ');
        const currentDomain = window.location.origin;
        setInviteLink(currentDomain + '/signup/' + docRef.id);
        console.log("New Invite : ",currentDomain + '/signup/' + docRef.id)
        toast.success('Invitation Link Generated');
      })
      .catch((error) => {
        console.error('Error adding document: ', error);
      });
  };

  const copyInvitationLink = () => {
    if (user?.giftStatus !== 'confirmed') {
      toast.error("Before inviting your friends, please make sure you've sent at least one gift.");
      return;
    }

    // Use the Clipboard API to copy text to the clipboard
    navigator.clipboard.writeText(inviteLink)
      .then(() => {
        // Copying to clipboard was successful
        toast.success('Invitation link copied to clipboard!');
      })
      .catch((err) => {
        // Unable to copy to clipboard, handle the error
        console.error('Failed to copy text: ', err);
        toast.error('Failed to copy invitation link. Please try again.');
      });
  };


  const auth = getAuth();
  useEffect(() => {
    if (auth.currentUser !== null) {
      const currentDomain = window.location.origin;
      const invitationLink = currentDomain + '/signup/' + auth.currentUser.uid;
      setInviteLink(invitationLink);
      // auth.currentUser.providerData.map((profile) => {
      //   setEmail(profile.email);
      //   setUser(profile.displayName);
      //   setProfile(profile.photoURL);
      // });
      getDoc(doc(db, 'users', auth.currentUser.uid)).then((user) => {
        setUser(user.data());
      });
    }
  }, []);
  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount className=" shadow">
            {/* <Avatar src={account.photoURL} alt="photoURL" /> */}

            <Box sx={{ ml: 2 }}>
              <Typography variant="h4" sx={{}}>
                User Info
              </Typography>{' '}
              <div className="d-flex ">
                <Iconify className="me-1" icon="uil:user" />
                <Typography variant="body1" sx={{}}>
                  user: {user?.username}
                </Typography>
              </div>
              <div className="d-flex align-items-center">
                <Iconify className="me-1" icon="solar:ranking-line-duotone" />
                <Typography variant="body1" sx={{}}>
                  Rank:{' '}
                  {user?.userBoards && user?.userBoards.length > 0
                    ? user?.userBoards[user?.userBoards?.length - 1]
                    : 'none'}
                </Typography>
              </div>
            </Box>
          </StyledAccount>
        </Link>
      </Box>
      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount className=" shadow">
            {/* <Avatar src={account.photoURL} alt="photoURL" /> */}

            <Box sx={{ ml: 2 }}>
              <Typography variant="h4" sx={{}}>
                Gifts
              </Typography>
              <div className="d-flex align-items-center">
                <Iconify className="me-1" icon="ph:gift-bold" />
                <Typography variant="body2" sx={{}}>
                  Recived: ${user.giftRecived || 0}
                </Typography>
              </div>
              <div className="d-flex align-items-center">
                <Iconify className="me-1" icon="mdi:invite" />
                <Typography variant="body2" sx={{}}>
                  Invites: {user?.invites?.length || 0}
                </Typography>
              </div>
            </Box>
          </StyledAccount>
        </Link>
      </Box>
      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount className="shadow">
            {/* <Avatar src={account.photoURL} alt="photoURL" /> */}

            <Box sx={{ ml: 2 }}>
              <Typography variant="h4" sx={{}}>
                Invitation Link
              </Typography>
              <Button
                onClick={() => generateInvitationLink()}
                fullWidth
                className="my-1 border-white text-white"
                variant="outlined"
              >
                <Iconify className="me-2" icon="pepicons-pencil:arrows-spin-circle" />
                Generate
              </Button>
              <Button
                onClick={() => copyInvitationLink()}
                fullWidth
                className="my-1  border-white text-white"
                variant="outlined"
              >
                <Iconify className="me-2" icon="ph:copy-bold" />
                Copy
              </Button>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={navConfig} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
