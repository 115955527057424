import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDjT03QIxpoKRhVRlY5NkWt8LM68qAuiJ8",
  authDomain: "giftprosperity-2f30f.firebaseapp.com",
  projectId: "giftprosperity-2f30f",
  storageBucket: "giftprosperity-2f30f.appspot.com",
  messagingSenderId: "278567676049",
  appId: "1:278567676049:web:5941b9333279895d7ff730",
  measurementId: "G-1F9XVW0ELG"
};

export const app = firebase.initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
