const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: 'mdi:view-dashboard',
  // },
  // {
  //   title: 'user',
  //   path: '/dashboard/user',
  //   icon: 'mdi:account',
  // },
  // {
  //   title: 'Categories',
  //   path: '/dashboard/category',
  //   icon: 'mdi:shape',
  // },
  // {
  //   title: 'Courses',
  //   path: '/dashboard/courses',
  //   icon: 'mdi:book-open-blank-variant',
  // },
  // {
  //   title: 'Companies',
  //   path: '/dashboard/companies',
  //   icon: 'mdi:domain',
  // },
  // {
  //   title: 'Messages',
  //   path: '/dashboard/chat',
  //   icon: 'mdi:chat',
  // },

  // {
  //   title: 'Logout',
  //   path: '/login',
  //   icon: 'mdi:logout-variant',
  // },
];

export default navConfig;
