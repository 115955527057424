import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function TermsAndConditions() {
  return (
    <>
      <Helmet>
        <title> Terms and Conditions| Gift Prosperity </title>
      </Helmet>

      <Container>
      <div>
      <>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "2.25pt",
      marginLeft: "0cm",
      fontSize: 31,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "bold",
        fontStyle: "normal"
      }}
    >
      Términos y condiciones
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Política de privacidad
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Saludos al sitio web de "Gift Prosperity".(incluidas sus subsidiarias,
      afiliadas, agentes y proveedores de servicios, colectivamente, “Gift
      Prosperity”, “nosotros”, “nos” y/o “nuestro”). Esta plataforma es operada
      por "Gift Prosperity" y ha sido creada para brindar información sobre
      nuestra actividad de obsequios y nuestra plataforma en línea diseñada para
      permitir a los usuarios interactuar entre sí de igual a igual para aceptar
      obsequios monetarios y acceder a nuestra academia. y cursos de
      autodesarrollo (junto con la Plataforma, los “Servicios”) para los
      usuarios y visitantes de nuestros Servicios (“usted”, “su”). Esta Política
      de Privacidad establece la "Gift Prosperity" con respecto a la
      información, incluidos los datos personales (“Datos Personales”) y otra
      información que se recopila de los participantes que utilizan la
      Plataforma.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Información que recopilamos
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Cuando interactúa con otros participantes a través del sitio web, podemos
      recopilar datos personales y otra información suya, como se describe con
      más detalle a continuación: Datos personales que usted proporciona a
      través de los servicios: recopilamos datos personales suyos (incluidos,
      entre otros). a, su nombre y apellido, dirección de correo electrónico y
      número de teléfono móvil) cuando proporciona voluntariamente dicha
      información, como cuando interactúa con otros participantes, se registra
      para participar en la actividad de obsequios. Siempre que “Gift Prosperity
      recopile datos personales, nos esforzamos por proporcionar un enlace a
      esta Política de privacidad.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Recopilamos estos datos personales únicamente con el fin de proporcionar
      al participante medios para interactuar con otros participantes dentro de
      las pautas de esta actividad de obsequio, así como para brindar acceso a
      la academia gratuita y cursos relacionados. Para obtener más detalles
      sobre por qué Para recopilar sus datos personales y cómo los utilizamos,
      consulte la sección siguiente titulada "Nuestro uso de datos personales y
      otra información". Si bien no tiene la obligación de proporcionar ningún
      dato personal, si decide retener dicha información, es posible que no
      podamos brindarle acceso para convertirse en un participante activo en
      esta actividad de obsequios.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Al proporcionarnos voluntariamente Datos personales, usted acepta que los
      usemos de acuerdo con esta Política de privacidad. Si proporciona Datos
      personales a los Servicios, reconoce y acepta que dichos Datos personales
      pueden transferirse desde su ubicación actual a los servidores de Gift
      Prosperity y los terceros autorizados aquí mencionados ubicados a nivel
      mundial.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Otra información
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Datos no personales: cuando interactúa con los participantes de Gift
      Prosperity a través del sitio web de obsequios, automáticamente recibimos
      y almacenamos cierta información personal no identificable. Dicha
      información, que se recopila pasivamente mediante diversas tecnologías, no
      puede utilizarse actualmente para identificarlo específicamente. Ejemplos
      de esta información personal no identificable incluyen el tipo de
      navegador de Internet o dispositivo móvil que utiliza, cualquier sitio web
      desde el cual haya accedido a los Servicios, su sistema operativo, datos
      de ubicación (que son anónimos y dónde inicia sesión a través de la
      Plataforma). Se le preguntará si acepta que los Servicios accedan a su
      ubicación, que puede actualizarse en cualquier momento en la configuración
      de su dispositivo) (“Datos no personales”). El sitio web de Gift
      Prosperity puede almacenar dichos datos no personales por sí mismo o dicha
      información puede incluirse en bases de datos que pertenecen y son
      mantenidas por los proveedores de servicios, afiliados o agentes de Gift
      Prosperity. Los Servicios pueden utilizar dichos Datos no personales para
      ayudarnos a proporcionar Servicios efectivos y recopilar información
      demográfica amplia para uso agregado.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Datos agregados: en un esfuerzo continuo por comprender y servir mejor a
      los participantes de la plataforma de obsequios, Gift Prosperity a menudo
      realiza investigaciones sobre la demografía, los intereses y el
      comportamiento de sus clientes en función de los datos personales, los
      datos no personales y otra información proporcionada. para nosotros. Esta
      investigación puede compilarse y analizarse de forma agregada y sin
      identificación, y Gift Prosperity puede compartir estos datos agregados
      con sus afiliados, agentes y socios comerciales. Esta información agregada
      no le identifica personalmente. Gift Prosperity también puede revelar
      estadísticas agregadas de usuarios para describir nuestra actividad de
      obsequios a partes de interés actuales y potenciales, y a otros terceros
      para otros fines legales y para la expansión de nuestra actividad de
      obsequios para mejorar la eficacia de la actividad.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Información de ubicación: cuando utiliza el sitio web de actividades de
      obsequios para interactuar, recopilar u organizar obsequios, los Servicios
      pueden solicitar que proporcione su ciudad o pueblo y su estado o
      provincia de residencia. Tenga en cuenta que otros usuarios de los
      Servicios pueden ver su ciudad o pueblo y estado o provincia de residencia
      en relación con la actividad de donación si está disponible en el
      Directorio de búsqueda pública de Gift Prosperity. También podemos
      utilizar su información de ubicación de forma agregada, como se describe
      anteriormente en la sección "Datos agregados".
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Integraciones de redes sociales: una de las reglas de la actividad de
      obsequios de Gift Prosperity es que no se mostrará, compartirá ni
      divulgará ninguna información, ya sea privada o de otro tipo, en ninguna
      plataforma de redes sociales.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      No tiene la opción de publicar su actividad de participación en los
      Servicios de redes sociales cuando accede al contenido a través de los
      Servicios (por ejemplo, NO puede publicar en Facebook que realizó una
      actividad en la Plataforma); usted reconoce que si decide hacerlo, corre
      el riesgo de perder su perfil y su participación adicional dentro de la
      plataforma de actividades de obsequios Gift Prosperity.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Servicios móviles: Los Servicios incluyen ciertos servicios que están
      disponibles a través de un dispositivo móvil, incluyendo (i) la capacidad
      de cargar contenido a los Servicios a través de un dispositivo móvil, (ii)
      la capacidad de navegar por la Plataforma y los Servicios desde un
      dispositivo móvil, y (iii) la capacidad de acceder a ciertas funciones a
      través de una aplicación descargada e instalada en un dispositivo móvil
      (colectivamente, los "Servicios Móviles"). En la medida en que acceda a
      los Servicios a través de un dispositivo móvil, es posible que se apliquen
      los cargos estándar, las tarifas de datos y otras tarifas de su proveedor
      de servicios inalámbricos. Además, su proveedor puede prohibir o
      restringir la descarga, instalación o uso de ciertos Servicios móviles, y
      es posible que no todos los Servicios móviles funcionen con todos los
      proveedores o dispositivos. Al utilizar los Servicios Móviles, usted
      acepta que otros participantes pueden comunicarse con usted con respecto a
      Gift Prosperity mediante SMS, MMS, mensajes de texto u otros medios
      electrónicos a su dispositivo móvil y que se nos puede comunicar cierta
      información sobre su uso de los Servicios móviles. En caso de que cambie o
      desactive su número de teléfono móvil, acepta actualizar de inmediato la
      información de su cuenta de perfil de Gift Prosperity para garantizar que
      sus mensajes no se envíen a la persona que adquiere su número anterior. Lo
      mismo ocurre en los casos en que cierre las direcciones de correo
      electrónico que se utilizaron para el registro de participación. usted
      acepta actualizar de inmediato la información de su cuenta de perfil de
      Gift Prosperity para garantizar que sus mensajes no se envíen a la persona
      que adquiere su número anterior. Lo mismo ocurre en los casos en que
      cierre las direcciones de correo electrónico que se utilizaron para el
      registro de participación. usted acepta actualizar de inmediato la
      información de su cuenta de perfil de Gift Prosperity para garantizar que
      sus mensajes no se envíen a la persona que adquiere su número anterior. Lo
      mismo ocurre en los casos en que cierre las direcciones de correo
      electrónico que se utilizaron para el registro de participación.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Datos personales de terceros: Los Servicios también pueden recopilar datos
      personales relacionados con terceros almacenados en las libretas de
      direcciones de su correo electrónico y de su dispositivo móvil, incluidos,
      entre otros, nombres, direcciones de correo electrónico y números de
      teléfono (colectivamente, "Datos personales de terceros"). ). Los
      Servicios pueden solicitar su permiso para acceder a dichos Datos
      personales de terceros.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Nuestro uso de datos personales y otra información
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Gift Prosperity utiliza los datos personales que usted proporciona de
      manera coherente con esta Política de privacidad. Si proporciona Datos
      personales por un motivo determinado, podemos utilizar los Datos
      personales en relación con el motivo por el cual se proporcionaron. Por
      ejemplo, si se comunica con nosotros por correo electrónico, utilizaremos
      los datos personales que proporcione para responder su pregunta o resolver
      su problema. Además, si proporciona datos personales sobre usted mismo, o
      ha proporcionado (o ha permitido el acceso a los Servicios) cualquier dato
      personal de terceros para obtener acceso o hacer uso de características
      particulares de los servicios, usaremos sus datos personales. y cualquier
      dato personal de terceros para brindarle acceso a dichos servicios y
      monitorear su uso de dichos servicios.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Gift Prosperity también puede utilizar sus datos personales y otros datos
      no personales recopilados a través de la actividad de obsequios basada en
      el sitio web para ayudarnos a proporcionar el alojamiento, procesar la
      información de interacción y cumplir con las solicitudes de información
      entre el donante y la leyenda en un tablero específico. , recibir y enviar
      comunicaciones, actualizar listas compartidas, analizar datos,
      proporcionar servicios de soporte u otras tareas de vez en cuando.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Gift Prosperity puede, con su consentimiento, utilizar sus datos
      personales para contactarlo en el futuro e informarle sobre los servicios
      que creemos que serán de su interés cuando lo permita la ley aplicable. Si
      lo hacemos, cada comunicación de marketing que le enviemos contendrá
      instrucciones que le permitirán "optar por no recibir" futuras
      comunicaciones de marketing. Además, si en algún momento no desea recibir
      comunicaciones de marketing futuras o desea que se elimine su nombre de
      nuestras listas de correo, comuníquese con nosotros como se indica a
      continuación.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      También podemos utilizar sus Datos personales y Datos personales de
      terceros para enviar comunicaciones relacionadas con los Servicios, de
      acuerdo con las leyes aplicables. Por ejemplo, podemos enviar correos
      electrónicos periódicos a los participantes registrados de la actividad de
      obsequios relacionados con sus interacciones recientes con otro
      participante. Además, si utiliza cualquier función de los Servicios que le
      permita comunicarse con terceros (como recomendar a un tercero los
      Servicios o comunicarse con ellos con respecto a esta oportunidad de
      actividad de obsequio), ya sea enviando Datos personales de terceros a la
      Servicios o permitir que los Servicios accedan automáticamente a Datos
      personales de terceros en su poder, usted reconoce y acepta que tiene la
      autoridad del tercero correspondiente para que podamos acceder y utilizar
      los Datos personales de terceros relevantes y que ha notificado a estos
      terceros y les ha informado cómo Gift Prosperity recopila y utiliza su
      información. para proporcionar acceso a la participación en la actividad
      de donación. Nos reservamos el derecho de identificarlo como la persona
      que realizó la referencia en cualquier mensaje que se les envíe. Podemos
      utilizar Datos personales de terceros para (1) contactar a dicho tercero
      utilizando los Datos personales de terceros proporcionados y/o (2)
      proporcionarle una plantilla de mensaje editable diseñada para facilitar
      la comunicación entre usted y dicho tercero a través del Servicios. Además
      del envío de las anteriores comunicaciones, También podemos enviarle
      recordatorios o mensajes relacionados a usted y a terceros en su nombre de
      vez en cuando, cuando lo permita la ley aplicable. En cada caso, dichas
      comunicaciones enviadas a terceros utilizando Datos personales de terceros
      proporcionarán un medio para "optar por no recibir" más comunicaciones de
      la misma naturaleza.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Si Gift Prosperity tiene la intención de utilizar datos personales de
      cualquier manera o para cualquier propósito que no sea consistente con
      esta Política de Privacidad, obtendremos su consentimiento informado
      previo según lo exige la ley aplicable.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Nuestra divulgación de datos personales y otra información
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Gift Prosperity no tiene como objetivo vender su información. Consideramos
      que esta información es una parte vital de nuestra relación con usted. Sin
      embargo, existen ciertas circunstancias en las que podemos compartir sus
      Datos personales con ciertos terceros sin previo aviso, como se establece
      a continuación:
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Mejoras y mejoras de la plataforma: a medida que mejoramos la plataforma
      de obsequios de Gift Prosperity, podríamos tener que proporcionar al
      proveedor de servicios datos que contengan información personal para
      importarlos a la plataforma mejorada/mejorada
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Agentes, consultores y terceros relacionados: Gift Prosperity, en
      ocasiones, puede contratar a otros proveedores de servicios para realizar
      funciones técnicas. Ejemplos de tales funciones incluyen el envío de
      información por correo y el mantenimiento de bases de datos. Cuando
      empleamos a otra entidad para realizar una función de esta naturaleza,
      solo les proporcionamos la información que necesitan para realizar su
      función específica y les exigimos que utilicen la información únicamente
      para los fines para los que fue recopilada.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Requisitos legales: Gift Prosperity puede divulgar sus Datos personales si
      así lo requiere la ley o si cree de buena fe que dicha acción es necesaria
      para (i) cumplir con una obligación legal, (ii) proteger y defender los
      derechos o propiedad de los creadores, desarrolladores, líderes y
      presentadores de Gift Prosperity (iii) actúan en circunstancias urgentes
      para proteger la seguridad personal de los usuarios de los Servicios o del
      público, o (iv) protegen contra responsabilidad legal.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Galletas
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Cuando interactúa con/a través del sitio web de actividades de obsequios,
      intentamos que esa experiencia sea simple y significativa. Cuando visita
      la Plataforma, el servidor web envía una cookie a su computadora o
      dispositivo móvil (según sea el caso). Las cookies son pequeñas piezas de
      información que se envían a su computadora o dispositivo móvil (según sea
      el caso) cuando visita un sitio web o accede o utiliza una aplicación
      móvil, y que almacenan y, a veces, rastrean información sobre su uso de la
      Plataforma (como sea el caso). Varias cookies que utilizamos duran solo
      mientras dura su sesión web o de aplicación y caducan cuando cierra su
      navegador o sale de la aplicación. Otras cookies se utilizan para
      recordarle cuando regresa a la Plataforma.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Algunas de las cookies utilizadas por la Plataforma las configuramos
      nosotros y otras las configuran terceros que brindan servicios en nuestro
      nombre.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      La mayoría de los navegadores web y de dispositivos móviles aceptan
      cookies automáticamente pero, si lo prefiere, puede cambiar su navegador
      para evitarlo o para que le notifique cada vez que se establece una
      cookie. También puede obtener más información sobre las cookies visitando
      www.allaboutcookies.org, que incluye información útil adicional sobre las
      cookies y cómo bloquearlas utilizando diferentes tipos de navegadores o
      dispositivos móviles. Sin embargo, tenga en cuenta que al bloquear o
      eliminar las cookies utilizadas en la Plataforma, es posible que no pueda
      aprovechar al máximo los Servicios.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      La Plataforma utiliza cookies y tecnologías similares a las cookies para
      recopilar información sobre su acceso y uso de la Plataforma. También
      puede actualizar su configuración de privacidad en su dispositivo
      configurando la propiedad de configuración "Limitar seguimiento de
      anuncios" y Diagnóstico y uso ubicada en la pantalla de configuración de
      su iPhone o iPad de Apple, o restableciendo su ID de Android a través de
      aplicaciones que están disponibles en Play. Almacenar. También puede
      detener la recopilación de información desinstalando la aplicación en su
      dispositivo y puede utilizar el proceso de desinstalación estándar
      disponible como parte de su dispositivo para este fin.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      A continuación se detalla información sobre los tipos de cookies que
      utilizamos y cómo las utilizamos:
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      • Esenciales: le permiten navegar y utilizar todas las funciones
      proporcionadas por nuestra Plataforma.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      • Cookies funcionales: Estas cookies personalizan elementos del diseño
      promocional y/o contenido de las páginas de la Plataforma y recuerdan que
      nos ha visitado antes; esto significa que podemos identificar la cantidad
      de visitantes únicos que recibimos. Esto nos permite asegurarnos de tener
      suficiente capacidad para la cantidad de usuarios que obtenemos. Entonces,
      cuando utiliza nuestra Plataforma, lo recordamos a usted, sus preferencias
      y la información que nos ha enviado para brindarle una experiencia
      personalizada.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      • Rendimiento y análisis: las cookies de rendimiento nos permiten
      recopilar información sobre cómo utiliza la Plataforma. Recopilan
      información estadística anónima sobre cómo utiliza la Plataforma (incluido
      cuánto tiempo pasa en la Plataforma) y desde dónde llegó a la Plataforma
      para que podamos mejorarla y saber qué funciones de la Plataforma son más
      populares entre los usuarios. Esta información no incluye Datos
      personales, aunque es posible que podamos asociar dicha información con
      cuentas de usuario una vez que la recibamos del tercero.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      • Publicidad y orientación: estas cookies recopilan información sobre las
      páginas que visita en línea. Recopilan información sobre las páginas que
      visita o las funciones de la Plataforma que utiliza, y también otra
      información sobre otros sitios web que visita, para ubicarlo en un
      "segmento de mercado". Esta información solo se recopila mediante
      referencia a la dirección IP o al identificador único de dispositivo que
      está utilizando (el identificador de cookie aplicable) y también puede
      incluir información sobre el condado o provincia y la ciudad o pueblo en
      el que se encuentra, junto con el nombre de su acceso a Internet.
      proveedor de servicio. Luego, esta información se utiliza para colocar
      anuncios basados en intereses en la Plataforma, que se cree que serán
      relevantes para su segmento de mercado. Esto puede hacer que vea anuncios
      de nuestra Plataforma cuando visite otros sitios web.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Utilizamos terceros, por ejemplo, Google Analytics, para analizar
      información estadística de los usuarios de la Plataforma y proporcionarle
      anuncios basados en intereses. Puede comprender mejor Google Analytics en
    </span>
    <span style={{ fontFamily: '"UICTFontTextStyleBody",serif' }}>
      &nbsp;&nbsp;
    </span>
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      https://www.google.com/intl/en/analytics/learn/privacy.html&nbsp;
    </span>
    <span style={{ fontFamily: '"UICTFontTextStyleBody",serif' }}>
      &nbsp;&nbsp;
    </span>
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      y obtener información sobre cómo administrar la configuración de Google
      Analytics en
    </span>
    <span style={{ fontFamily: '"UICTFontTextStyleBody",serif' }}>
      &nbsp;&nbsp;
    </span>
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      https://tools.google.com/dlpage/ gaoptout.&nbsp;
    </span>
    <span style={{ fontFamily: '"UICTFontTextStyleBody",serif' }}>&nbsp;</span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Sus opciones
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Como se menciona a lo largo de esta Política de Privacidad, puede visitar
      la Plataforma sin proporcionar ningún Dato Personal. Si elige no
      proporcionar ningún dato personal, es posible que no pueda utilizar
      ciertos servicios.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Exclusión
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Esta Política de Privacidad no se aplica a ningún dato personal recopilado
      por Gift Prosperity que no sea los datos personales recopilados a través
      del sitio web de Gifting. Esta Política de Privacidad no se aplicará a
      ninguna información no solicitada que usted proporcione a Gift Prosperity
      a través de los Servicios o por cualquier otro medio. Esto incluye, entre
      otros, información publicada en áreas públicas de los Servicios, como
      foros o secciones de comentarios, cualquier comunicación de atención al
      cliente, cualquier idea para nuevas reglas de obsequios o modificaciones a
      la actividad de obsequios existente, y otras presentaciones no solicitadas
      (colectivamente , “Información no solicitada”). Usted acepta que cualquier
      información no solicitada pasa a ser propiedad intelectual de Gift
      Prosperity en la medida permitida por la ley aplicable.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Children
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Gift Prosperity no recopila intencionalmente Datos personales de niños
      menores de 18 años. Si es menor de 18 años, no utilice los Servicios ni
      envíe ningún Dato personal a través de los Servicios. Alentamos a los
      padres y tutores legales a monitorear el uso de Internet de sus hijos y
      ayudar a hacer cumplir nuestra Política de Privacidad instruyendo a sus
      hijos a que nunca proporcionen Datos Personales en los Servicios sin su
      permiso. Si tiene motivos para creer que un niño menor de 18 años ha
      proporcionado datos personales a Gift Prosperity a través de la plataforma
      de actividades de obsequios, contáctenos a
      support@giftprosperityglobal.com y nos esforzaremos por eliminar esa
      información de nuestras bases de datos.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Enlaces a otros sitios web
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Esta Política de Privacidad se aplica únicamente a los Servicios. Los
      Servicios pueden contener enlaces a otros sitios web no operados ni
      controlados por Gift Prosperity (los "Sitios de terceros"). Las políticas
      y procedimientos que describimos aquí no se aplican a los Sitios de
      terceros. Los enlaces de los Servicios no implican que Gift Prosperity
      respalde o haya revisado los Sitios de terceros. Le sugerimos comunicarse
      directamente con esos sitios para obtener información sobre sus
      respectivas políticas de privacidad.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Seguridad
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Gift Prosperity toma medidas razonables para proteger los Datos personales
      proporcionados a través de los Servicios contra pérdida, uso indebido y
      acceso no autorizado, divulgación, alteración o destrucción. Sin embargo,
      ninguna transmisión por Internet o por correo electrónico es completamente
      segura o está libre de errores. En particular, el correo electrónico
      enviado hacia o desde los Servicios puede no ser seguro. Por lo tanto,
      debes tener especial cuidado al decidir qué información nos envías por
      correo electrónico. Tenga esto en cuenta al revelar cualquier dato
      personal a Gift Prosperity a través de Internet.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Otros términos y condiciones
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Su acceso y uso del sitio web de la actividad de obsequios está sujeto a
      los Términos de servicio en Términos y otros términos que puedan estar
      disponibles para usted en relación con su uso de la actividad de
      obsequios.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      y otros términos que puedan estar disponibles para usted en relación con
      su uso de la actividad de obsequio.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Cambios en la política de privacidad de Gift Prosperity
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Las pautas y términos de las oportunidades de actividades de donación
      pueden cambiar de vez en cuando. Como resultado, en ocasiones puede ser
      necesario que Gift Prosperity realice cambios en esta Política de
      Privacidad. Gift Prosperity se reserva el derecho de actualizar o
      modificar esta Política de Privacidad en cualquier momento y de vez en
      cuando. Cualquier cambio material estará precedido por un aviso publicado
      en el sitio web de actividad de obsequios correspondiente de cada
      participante, incluidas todas las plataformas de grupos comunitarios. Si
      no está de acuerdo con nuestras revisiones de la Política de privacidad,
      puede desactivar su cuenta o interrumpir su participación en los
      obsequios. actividad. Revise esta política periódicamente y especialmente
      antes de proporcionar cualquier dato personal. Esta Política de Privacidad
      fue actualizada por última vez en la fecha indicada anteriormente.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Acceso a la información; Gift Prosperity
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Para mantener sus datos personales precisos, actualizados y completos,
      revise su perfil y modifíquelo/edítelo y complételo con la información
      relevante. Puede solicitar por escrito copias de sus datos personales en
      poder de los administradores del sitio web de actividades de obsequios. Si
      sus Datos personales que tenemos son inexactos, háganoslo saber y haremos
      las modificaciones necesarias, borraremos o bloquearemos los Datos
      personales relevantes según lo solicite y le notificaremos dentro de los
      treinta (30) días posteriores a dicha solicitud que se ha tomado la acción
      correspondiente. ha sido tomada.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      No dude en contactarnos si tiene alguna pregunta sobre la Política de
      privacidad de Gift Prosperity o las prácticas de información de la
      actividad de obsequios basada en este sitio web.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Puede contactarnos de la siguiente manera:
      support@giftprosperityglobal.com.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      © 2023 Gift Prosperity
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      TÉRMINOS DE PARTICIPACIÓN
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Reconozco que entiendo y acepto que...
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Mi cuenta Gift Prosperity es un activo. Puedo participar en la actividad
      de donación de Gift Prosperity o entregársela a otros siempre y cuando mi
      conducta coincida con los Términos y condiciones que se enumeran a
      continuación.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      1 - Gift Prosperity es una actividad de donación y no una inversión ni una
      actividad comercial. La velocidad de avance de un tablero a otro no está
      garantizada ni prevista, sino que está relacionada con el ritmo de
      participación de los demás participantes en los tableros.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      2 - El espíritu y la conducta de los participantes en Gift Prosperity es
      unirse como una comunidad creando abundancia para todos, elevando a todos
      los participantes con buena reputación, para hacer y recibir donaciones
      incondicionales donando generosamente y recibiendo con gracia.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      3 - Gift Prosperity no es responsable de ningún aspecto de la transacción
      de donación financiera entre yo y cualquier otro Participante. La forma
      financiera de cada obsequio debe acordarse entre Legend (receptor) y yo
      cuando soy el Gifter, y con los Gifters cuando soy el Legend.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      Además del importe del regalo, el remitente del regalo siempre es
      responsable de los costes de la transacción de envío de su parte. La
      Leyenda (receptor) siempre es responsable de recibir los costos de
      transacción de su parte.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      4 - Mi participación en Gift Prosperity comenzará con una posición como
      Gifter en una posición del tablero de $100 y requiere que haga un regalo
      incondicional a la Leyenda en ese tablero y registre a 2 invitados en la
      plataforma para cuando me convierta en Leyenda en el tablero de $100 .
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      5 - Teniendo en cuenta el hecho de que los Participantes están
      distribuidos en zonas horarias de todo el mundo, iniciaré la comunicación
      entre yo y la Leyenda (receptor del regalo) dentro de las 48 horas
      posteriores a su colocación en un tablero.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      6 - Al avanzar a la posición Leyenda en cualquier tablero, confirmaré cada
      regalo recibido en la plataforma Gift Prosperity tan pronto como lo reciba
      por mi parte o se proporcione una prueba aceptable de transacción.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      7 - Los plazos de comunicación y los términos y condiciones de las
      transacciones de obsequio son idénticos en cada nivel en el que participo.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      8 - Reconociendo que la vida y los eventos externos pueden impedir la
      comunicación inmediata, los Gifters pueden ser eliminados de un tablero si
      informan a la Leyenda que no pueden hacer su regalo en ese momento O si
      múltiples intentos de comunicarse con ellos a través de su correo
      electrónico no han tenido éxito. número de teléfono, canales comunitarios
      y administrador comunicándose con los familiares más cercanos.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      9 - Si la leyenda no responde dentro de las 72 horas, el sistema los
      dejará al margen para que todos puedan continuar hacia arriba, y se les
      pedirá que comiencen de nuevo. Es muy importante que como Leyenda estés
      totalmente comprometido y disponible en el programa.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      10 - El algoritmo del sistema moverá mis posiciones fuera de los tableros
      (a una posición 'lateral') hasta que haya 2 invitados confirmados en buen
      estado y los obsequios se hayan realizado y confirmado en el sistema.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      11 - Cuando el seguimiento rápido está disponible para mí, es una opción
      para mí utilizar esa función, no una obligación. Si elijo usarlo, tanto la
      comunicación como mi donación incondicional se harán a la Leyenda de
      manera oportuna en cada nuevo nivel al ser colocado como Gifter.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    &nbsp;
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 19,
      fontFamily: '".AppleSystemUIFont",serif'
    }}
  >
    <span
      style={{
        fontFamily: '"UICTFontTextStyleBody",serif',
        fontWeight: "normal",
        fontStyle: "normal"
      }}
    >
      12 - Cuando la clonación está disponible para mí y elijo usarla, mi regalo
      incondicional se hará a la Leyenda en cada nuevo tablero de $100 y
      registraré a 2 invitados en la plataforma cuando me convierta en Leyenda
      en ese tablero.
    </span>
  </p>
  <p
    style={{
      margin: "0cm",
      marginBottom: ".0001pt",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    &nbsp;
  </p>
</>

    </div>
      </Container>
    </>
  );
}
