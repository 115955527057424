import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { styled, alpha } from '@mui/material/styles';
import Iconify from 'src/components/iconify/Iconify';

import {
  Divider,
  TextField,
  Grid,
  Paper,
  Typography,
  ListItem,
  Fab,
  ListItemText,
  List,
  Avatar,
  Input,
  Button,
  Stack,
  Checkbox,
  Container,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Link, useParams } from 'react-router-dom';
import { db } from '../firebase';
import { toast } from 'react-toastify';
import {
  doc,
  getDoc,
  getDocs,
  orderBy,
  onSnapshot,
  query,
  where,
  collection,
  updateDoc,
  addDoc,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  // chatSection: {
  //   width: '100%',
  //   height: '90vh',
  // },
  headBG: {
    backgroundColor: '#e0e0e0',
  },
  borderRight500: {
    borderRight: '1px solid #e0e0e0',
  },
  messageArea: {
    height: '60vh',
    overflowY: 'auto',
  },
});
const StyledGrid = styled('Grid')(({ theme }) => ({
  color: '#fff',
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.logo[0], 1),
}));

const Chat = () => {
  const auth = getAuth();
  const classes = useStyles();
  const [boardData, setBoardData] = useState();
  const [participantsData, setParticipantsData] = useState([]);
  const [message, setMessage] = useState('');
  const [userData, setUserData] = useState({});

  const { board } = useParams();
  const [chatMessages, setChatMessages] = useState([]);
  const chatContainerRef = useRef(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const boardQuery = query(collection(db, board), where('participants', 'array-contains', auth.currentUser.uid));

        // Attach a listener to the query using onSnapshot
        const unsubscribe = onSnapshot(boardQuery, async (snapshot) => {
          if (!snapshot.empty) {
            const firstMatchingBoard = snapshot.docs[0];

            const boardId = firstMatchingBoard.id;
            const boardData = firstMatchingBoard.data();

            boardData.id = boardId;

            const participantsData = [];

            // Create an array of promises to fetch user data
            const fetchUserPromises = boardData.participants?.map(async (user) => {
              const userData = await getDoc(doc(db, 'users', user));
              participantsData.push(userData.data());
            });

            await Promise.all(fetchUserPromises);

            setBoardData(boardData);
            setParticipantsData(participantsData);
          } else {
            toast.error('Error Board Not Found. Contact Support');
            console.log('No matching boards found.');
          }
        });

        return unsubscribe;
      } catch (error) {
        toast.error('Error Board Not Found. Contact Support');
        console.error('Error fetching boards:', error);
      }
    };

    const unsubscribe = fetchData();

    fetchData();
  }, [board]);

  useEffect(() => {
    if (auth.currentUser.uid) {
      getDoc(doc(db, 'users', auth.currentUser.uid)).then((user) => {
        setUserData(user.data());
      });
    }
  });

  useEffect(() => {
    const getMessages = () => {
      const chatMessagesQuery = query(collection(db, 'chats', boardData?.id, 'messages'), orderBy('timestamp', 'asc'));

      const handleSnapshot = (snapshot) => {
        const chatMessagesData = snapshot.docs.map((doc) => doc.data());
        setChatMessages(chatMessagesData);
      };

      const unsubscribe = onSnapshot(chatMessagesQuery, handleSnapshot);

      return () => {
        unsubscribe();
      };
    };

    if (boardData) {
      getMessages();
    }
  }, [boardData]);

  ///scroll chat to bottom
  useEffect(() => {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [chatMessages]);
  const handleSubmit = async () => {
    if (message.trim() !== '') {
      try {
        // Assuming you have a Firestore instance named "db" set up
        const boardId = boardData.id;

        // Add the message to the "messages" collection within the specified board document
        const messageDocRef = await addDoc(collection(db, 'chats', boardId, 'messages'), {
          message: message,
          sender: auth.currentUser.uid,
          username: userData.username,
          timestamp: new Date(),
        });

        console.log(`Message added with ID: ${messageDocRef.id}`);

        // Clear the input field
        setMessage('');
      } catch (error) {
        console.error('Error adding message: ', error);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div>
      <Helmet>
        <title> Chats | Gift Prosperity</title>
      </Helmet>

      <Container>
        <div className=" d-flex justify-content-between">
          <div className="col-md-6">
            <Typography variant="h4" sx={{ mb: 5 }}>
              {boardData?.boardName} Chat
            </Typography>
          </div>
          <div className="col-md-6 d-flex align-items-center">
            <Link className="ms-auto " to="/dashboard/app">
              <Button variant="contained">Go to Boards</Button>
            </Link>
          </div>
        </div>

        <Grid container component={Paper} className={classes.chatSection + ' rounded shadow'}>
          <Grid item xs={12} className="border m-2 p-2 rounded">
            <List className="p-2">
              <Typography>
                {' '}
                1 - Gifter is to reach out to Legend IMMEDIATELY upon joining every board, to arrange gifting.{' '}
              </Typography>
              <Typography>
                {' '}
                2 - Legend may reach out to Gifter to inquire about Gifter's available gifting pathways.
              </Typography>
              <Typography>
                {' '}
                3 - Legend may remove unresponsive Gifters between 6 and 72 hours, if Gifter enters a seat and no
                communication is received from Gifter, or no action is initiated by the Gifter to make the gift.
              </Typography>
            </List>
          </Grid>
          <Grid item md={3} className={classes.borderRight500}>
            {/* <Divider />
            <Grid item xs={12} style={{ padding: '10px' }}>
              <TextField id="outlined-basic-email" label="Search" variant="outlined" fullWidth />
            </Grid>
            <Divider /> */}
            <List style={{ overflow: 'hidden' }}>
              <Typography className="text-center" variant="h4">
                Borad Members
              </Typography>
              {participantsData.map((user, index) => {
                return (
                  <ListItem button key={index}>
                    <Avatar className="me-3" alt={`User ${index + 1}`} src="" />
                    <ListItemText primary={user.username} />
                  </ListItem>
                );
              })}
            </List>
          </Grid>
          <Grid item md={9}>
            <List ref={chatContainerRef} className={classes.messageArea}>
              {chatMessages?.map((chat) => {
                return (
                  <ListItem key="1">
                    <StyledGrid
                      className={
                        chat.sender == auth.currentUser.uid
                          ? 'ms-auto mt-2  p-3 rounded'
                          : 'me-auto mt-2  p-3 rounded bg-white border text-dark'
                      }
                    >
                      <Grid item xs={12}>
                        <ListItemText align="left" primary={chat.message}></ListItemText>
                      </Grid>

                      <Grid item xs={12}>
                        <ListItemText
                          align="right"
                          secondary={'Sent by ' + chat.username + ' - ' + chat.timestamp.toDate().toLocaleString()}
                        ></ListItemText>
                      </Grid>
                    </StyledGrid>
                  </ListItem>
                );
              })}
            </List>
            <Divider />
            <Grid container style={{ padding: '20px' }}>
              <Grid item xs={11}>
                <TextField
                  id="outlined-basic-email"
                  label="Enter Message"
                  fullWidth
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </Grid>
              <Grid xs={1} align="right">
                <Fab color="primary" aria-label="add" onClick={handleSubmit}>
                  <Iconify icon="mdi:send" />
                </Fab>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Chat;
