import React from 'react';
import { styled, alpha } from '@mui/material/styles';

const FooterDiv = styled('div')(({ theme }) => ({


  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.logo[0], 1.0),
}));
export default function Footer() {
  return (
    <FooterDiv className="w-100 mt-5 text-center p-3 mx-0 text-white">
      Please email support@giftprosperity.com for any help or concerns.
    </FooterDiv>
  );
}
